<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="5">
              <b-form-group
                label="Nome / E-mail"
                label-for="name"
              >
                <b-form-textarea
                  id="name"
                  v-model="filtersValues.emails"
                  :disabled="isLoading"
                  rows="6"
                  @keydown.enter="handleCtrlEnter"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="7"
              class="form-buttons text-right d-flex justify-content-end flex-wrap align-content-end"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  :disabled="isLoading"
                  variant="primary"
                  @click="getUsers"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  :disabled="isLoading"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
            <b-col
              class="text-right"
            >
              <b-dropdown
                v-if="$can('read', 'Users')"
                :class="{ invisible: selectedUsers.length === 0 }"
                text="Ações com usuários marcados"
              >
                <b-dropdown-item-button
                  @click="() => handleAssignKanban(selectedUsers)"
                >
                  Atribuir Kanban
                </b-dropdown-item-button>

                <b-dropdown-item-button
                  @click="() => handleSanitationKanban(selectedUsers)"
                >
                  Enviar para Higienização
                </b-dropdown-item-button>

                <b-dropdown-item-button
                  @click="() => handleIssueMentoring(selectedUsers)"
                >
                  Enviar para Chamado Canal VIP
                </b-dropdown-item-button>

                <b-dropdown-item-button
                  @click="() => handleAssistanceKanban(selectedUsers)"
                >
                  Enviar para Assitência
                </b-dropdown-item-button>
              </b-dropdown>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                show-empty
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #empty>
                  <h4 class="text-center mt-2 mb-2">
                    Nenhum resultado encontrado
                  </h4>
                </template>

                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(select)>
                  <div class="text-nowrap">
                    <b-form-checkbox
                      :checked="results.length === selectedUsers.length"
                      @change="toggleAll"
                    />
                  </div>
                </template>

                <template #head(status)>
                  <div class="text-nowrap">
                    Status
                  </div>
                </template>

                <template #head(type)>
                  <div class="text-nowrap">
                    Tipo
                  </div>
                </template>

                <template #head(name)>
                  <div class="text-nowrap">
                    Nome
                  </div>
                </template>

                <template #head(company_name)>
                  <div class="text-nowrap">
                    Empresa
                  </div>
                </template>

                <template #head(allEmails)>
                  <div class="text-nowrap">
                    E-mails
                  </div>
                </template>

                <template #head(allPhones)>
                  <div class="text-nowrap">
                    Telefones
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(select)="row">
                  <b-form-checkbox
                    v-model="selectedUserIds"
                    name="selectedUsers"
                    :value="row.item.id"
                  />
                </template>

                <template #cell(type)="row">
                  {{ $t(`users.columns.type.${row.item.type}`) }}
                </template>

                <template #cell(status)="row">
                  {{ $t(`users.columns.status.${row.item.status}`) }} <span v-if="row.item.status !== 'basic' && row.item.status !== 'review'"><br>{{ row.item.plan_group_name }} {{ row.item.payment_interval ? ' - ' + $t(`financial.columns.payment_interval.${row.item.payment_interval}`) : '' }}</span>
                </template>

                <template #cell(allEmails)="row">
                  {{ row.item.allEmails.join('\n') }}
                </template>

                <template #cell(allPhones)="row">
                  {{ row.item.allPhones.join('\n') }}
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      v-if="$can('read', 'Users')"
                      variant="flat-primary"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.id } }"
                      title="Editar"
                      target="_blank"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                    <b-dropdown
                      v-if="$can('read', 'Users')"
                      title="Ações com o usuário"
                      variant="flat-primary"
                    >
                      <b-dropdown-item-button
                        @click="() => handleAssignKanban([row.item])"
                      >
                        Atribuir Kanban
                      </b-dropdown-item-button>

                      <b-dropdown-item-button
                        @click="openMoveCard(row.item.kanban)"
                      >
                        Mover Card
                      </b-dropdown-item-button>

                      <b-dropdown-item-button
                        @click="handleSanitationKanban([row.item])"
                      >
                        Enviar para Higienização
                      </b-dropdown-item-button>

                      <b-dropdown-item-button
                        @click="() => handleIssueMentoring([row.item])"
                      >
                        Enviar para Chamado Canal VIP
                      </b-dropdown-item-button>

                      <b-dropdown-item-button
                        @click="() => handleAssistanceKanban([row.item])"
                      >
                        Enviar para Assitência
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="move-card-modal"
      hide-footer
    >
      <move-card :data="cardData" />
    </b-modal>

    <b-modal
      ref="assign-kanban-modal"
      hide-footer
      size="lg"
      title="Atribuir Kanban"
      :no-close-on-backdrop="preventCloseModal"
      :no-close-on-esc="preventCloseModal"
      :hide-header-close="preventCloseModal"
    >
      <modal-assign-kanban
        :data="dataAssignKanban"
        @prevent-close-modal="changePreventCloseModal"
        @hide="hideAssignKanbanModal"
        @force-data-assign-kanban="handleAssignKanban"
      />
    </b-modal>

    <b-modal
      ref="sanitation-kanban-modal"
      hide-footer
      size="lg"
      :title="modalTitle"
      :no-close-on-backdrop="preventCloseModal"
      :no-close-on-esc="preventCloseModal"
      :hide-header-close="preventCloseModal"
    >
      <modal-kanban-additional
        :data="dataKanbanAdditional"
        :type="additionalType"
        @prevent-close-modal="changePreventCloseModal"
        @hide="hideKanbanAdditionalModal"
      />
    </b-modal>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import {
  BRow,
  BCol,
  BTable,
  BFormTextarea,
  BFormCheckbox,
  BFormGroup,
  BPaginationNav,
  BSpinner,
  BButton,
  BCard,
  BBreadcrumb,
  BDropdown,
  BDropdownItemButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalAssignKanban from './ModalAssignKanban.vue'
import ModalKanbanAdditional from './ModalKanbanAdditional.vue'
import MoveCard from '../../kanban/MoveCard.vue'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BPaginationNav,
    BSpinner,
    BButton,
    BBreadcrumb,
    BDropdown,
    BDropdownItemButton,
    ModalAssignKanban,
    ModalKanbanAdditional,
    MoveCard,
  },
  data() {
    return {
      userService: null,
      isLoading: false,
      isSubmited: false,
      selectedUserIds: [],
      cardData: {},
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Usuários Ativos',
          active: true,
        },
      ],

      filtersInitialValues: {},
      filtersValues: {
        emails: '',
        type: 'all',
        status: 'all',
        sortBy: 'id',
        sortDesc: true,
      },

      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      formatDateTimeDbToView,
      preventCloseModal: false,
      dataAssignKanban: null,
      dataKanbanAdditional: null,
      additionalType: 'sanitation',
    }
  },
  computed: {
    selectedUsers() {
      return this.results.filter(user => this.selectedUserIds.includes(user.id))
    },
    variant() {
      return document.body.classList.contains('dark-layout') ? 'dark' : undefined
    },
    modalTitle() {
      if (this.additionalType === 'sanitation') {
        return 'Enviar para Kanban de Higienização'
      }

      if (this.additionalType === 'assistance') {
        return 'Enviar para Kanban de Assistência'
      }

      if (this.additionalType === 'issue_success') {
        return 'Enviar para Chamado Canal VIP'
      }

      return 'Enviar para ...'
    },
  },

  watch: {
    currentPage() {
      this.getUsers()
    },
  },

  created() {
    this.userService = new UserService()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'select', sortable: false, thStyle: 'width: 20px' },
      { key: 'id', sortable: false, thStyle: 'width: 120px' },
      { key: 'type', sortable: false, thStyle: 'width: 170px' },
      { key: 'name', sortable: false },
      { key: 'company_name', sortable: false },
      { key: 'allEmails', sortable: false },
      { key: 'allPhones', sortable: false, thStyle: 'width: 180px' },
      { key: 'status', sortable: false },
      { key: 'actions', sortable: false, thStyle: 'width: 180px' },
    ]
  },

  methods: {
    handleCtrlEnter(e) {
      if (e.ctrlKey) {
        this.getUsers()
      }
    },

    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.results = []
      this.isSubmited = false
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getUsers()
    },

    getUsers() {
      if (this.isLoading) {
        return false
      }

      this.isSubmited = true
      this.isLoading = true
      this.userService.listActiveUsers(this.filtersValues.emails.split('\n').filter(f => f), this.currentPage).then(response => {
        if (response.data !== null) {
          const results = response.data.data.users.data.map(m => {
            const allPhones = [m.cell_phone || '', ...(m.phones || []), ...(m.phones_cms || [])]
            return {
              ...m,
              allEmails: [m.email, ...(m.emails || []), ...(m.emails_cms || [])],
              allPhones,
              allPhonesClean: allPhones.map(phone => phone.replace(/[^0-9]/g, '')),
              plan_group_name: (m.last_plan !== null) ? m.last_plan.split(',')[0] : '',
              payment_interval: (m.last_plan !== null) ? m.last_plan.split(',')[1] : '',
              kanban: m.kanban,
            }
          })

          this.results = []
          this.filtersValues.emails.split('\n').filter(f => f).forEach(email => {
            const matchId = results.filter(f => f.id === parseInt(email, 10))
            if (matchId.length > 0) {
              this.results = [
                ...this.results,
                ...matchId,
              ]
            } else {
              const matchEmail = results.filter(f => f.allEmails.filter(f2 => f2.toLowerCase().includes(email.toLowerCase())).length > 0)
              if (matchEmail.length > 0) {
                this.results = [
                  ...this.results,
                  ...matchEmail,
                ]
              } else {
                const matchPhone = results.filter(f => f.allPhonesClean.filter(f2 => f2.replace(/[^0-9]/gi, '').includes(email.replace(/[^0-9]/gi, ''))).length > 0 && email.replace(/[^0-9]/gi, '').length > 8)
                if (matchPhone.length > 0) {
                  this.results = [
                    ...this.results,
                    ...matchPhone,
                  ]
                }
              }
            }
          })

          if (this.results.length === 0) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ops!',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Nenhum resultado encontrado com os termos pesquisados',
              },
            })
          }

          this.pages = {
            ...response.data.data.users,
            data: null,
          }
        }
        this.isLoading = false
      })

      return true
    },

    openAssignKanbanModal() {
      this.$refs['assign-kanban-modal'].show()
    },

    openKanbanAdditionalModal() {
      this.$refs['sanitation-kanban-modal'].show()
    },

    hideAssignKanbanModal() {
      this.$refs['assign-kanban-modal'].hide()
    },

    hideKanbanAdditionalModal() {
      this.$refs['sanitation-kanban-modal'].hide()
    },

    changePreventCloseModal(event) {
      this.preventCloseModal = event
    },

    handleAssignKanban(data) {
      this.dataAssignKanban = data
      this.openAssignKanbanModal()
    },

    handleSanitationKanban(data) {
      this.additionalType = 'sanitation'
      this.dataKanbanAdditional = data
      this.openKanbanAdditionalModal()
    },

    handleAssistanceKanban(data) {
      this.additionalType = 'assistance'
      this.dataKanbanAdditional = data
      this.openKanbanAdditionalModal()
    },

    handleIssueMentoring(data) {
      this.additionalType = 'issue_success'
      this.dataKanbanAdditional = data
      this.openKanbanAdditionalModal()
    },

    openMoveCard(data) {
      this.cardData = data
      this.$refs['move-card-modal'].show()
    },

    toggleAll() {
      if (this.selectedUserIds.length === this.results.length) {
        this.selectedUserIds = []
      } else {
        this.selectedUserIds = this.results.map(m => m.id)
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
