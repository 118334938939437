<template>
  <div>
    <b-alert
      variant="info"
      show
      class="
        p-1
      "
    >
      <p>
        Todos os usuários selecionados serão enviados para {{ columString }}.
      </p>
    </b-alert>
    <validation-observer ref="rulesAssignTo">
      <b-form>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required|min:10"
            >
              <b-form-group
                label="Descrição"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  rows="6"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <b-form-group
                label="Consultor"
                label-for="manager"
              >
                <b-form-select
                  id="manager"
                  v-model="manager"
                  :options="selectedRoleManagersOptions"
                />
              </b-form-group>

              <b-form-group
                label="Anexo"
                label-for="attachment"
              >
                <b-form-file
                  :id="`attachment_file_${attachment.id}`"
                  v-model="attachment.file"
                  :disabled="isLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right mt-1">
            <b-button
              variant="primary"
              :disabled="isLoading"
              @click="handleActionAssignTo"
            >
              <span v-if="multipleData">
                Atribuir
                <strong>
                  {{ data.length }}
                </strong>
                usuários
              </span>
              <span v-else>
                Atribuir usuário
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BAlert,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  salesLeadOriginOptions,
  roleSuccessSubscriberOrigins,
} from '@/utils/options'
import toast from '@/mixins/toast'
import KanbanService from '@/services/kanbanService'
import { types } from 'util'

export default {
  components: {
    BFormFile,
    BFormSelect,
    BAlert,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [
    toast,
  ],
  props: {
    data: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      ToastificationContent,
      attachment: {
        id: 0,
        file: null,
        name: null,
        path: null,
        isFromTemplate: false,
      },
      isLoading: false,
      kanbanService: null,
      description: '',
      force: false,

      assignTo: null,
      manager: null,

      salesLeadOrigin: null,
      successSubscriberOrigin: null,
      salesLeadOriginOptions: salesLeadOriginOptions.filter(f => f.assignable),
      roleSuccessSubscriberOrigins,
    }
  },
  computed: {
    types() {
      return types
    },
    columString() {
      if (this.type === 'sanitation') {
        return 'Higienização'
      }

      if (this.type === 'assistance') {
        return 'Assistência'
      }

      return 'Chamado Canal VIP'
    },

    role() {
      if (this.type === 'sanitation') {
        return 'service_qualification'
      }

      return ''
    },

    selectedRoleManagersOptions() {
      return [
        {
          text: '-- Sortear --',
          value: null,
        },
        ...(JSON.parse(localStorage.getItem('managers')) || [])
          .filter(f => !f.deleted_at && ((this.role === '' && f.permission_type !== 'root') || f.permission_type === this.role))
          .map(m => ({
            value: m.id,
            text: m.name,
          })),
      ]
    },

    variant() {
      return document.body.classList.contains('dark-layout') ? 'dark' : undefined
    },

    multipleData() {
      return (this.data || []).length > 1
    },
  },

  watch: {
    assignTo() {
      this.manager = this.selectedRoleManagersOptions[0].value
    },
  },

  async created() {
    this.kanbanService = new KanbanService()
  },

  methods: {
    handleActionAssignTo() {
      this.$refs.rulesAssignTo.validate().then(success => {
        if (success) {
          this.handleAssignTo()
        } else {
          this.toastDanger('Erro de validação', 'Preencha corretamente os campos')
        }
      })
    },

    hide() {
      this.$refs['assign-kanban-modal-force'].hide()
    },

    getApi() {
      if (this.type === 'sanitation') {
        return this.kanbanService.addUsersInSanitation
      }

      if (this.type === 'assistance') {
        return this.kanbanService.addUsersInCercAssistance
      }

      return this.kanbanService.addUsersInIssueMentoring
    },

    handleAssignTo() {
      this.isLoading = true
      this.$emit('prevent-close-modal', true)

      const formData = new FormData()
      this.data.forEach(user => {
        formData.append('users[]', user.id)
      })

      formData.append('description', this.description)
      formData.append('manager', this.manager)
      if (this.attachment.file !== null) {
        formData.append(`attachment_file_${this.attachment.id}`, this.attachment.file)
      }

      const apiFunction = this.getApi()
      apiFunction(formData).then(response => {
        if (response.status === 200) {
          this.$emit('assigned-users', this.data)
          this.toastSuccess(`Enviado${this.multipleData ? 's' : ''} para ${this.columString} com sucesso!`)
          this.$emit('hide')
        } else {
          this.toastDanger('Erro!', 'Ops, algo deu errado!')
        }
      }).catch(() => {
        this.toastDanger('Erro!', 'Ops, algo deu errado!')
      }).finally(() => {
        this.isLoading = false
        this.$emit('prevent-close-modal', false)
      })
    },
  },
}
</script>

<style>

</style>
